import { IPerson, peopleCollection } from '../../utils/data';
import { useFirestoreInfiniteQuery } from '@react-query-firebase/firestore';
import { Box, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import PersonCard from '../PersonCard';
import './Directory.css';
import { query, where, limit, startAfter, orderBy } from 'firebase/firestore';
import { useSearchParams } from 'react-router-dom';
import useFetchNextPage from '../../hooks/useFetchNextPage';

const ITEMS_PER_PAGE = 4;

export default function Directory({ fullWidth = false }: { fullWidth?: boolean }) {
  const [params] = useSearchParams();
  const term = params.get('term');
  const school = params.get('school');
  const approvedPeople = query(
    peopleCollection,
    where('approved', '==', true),
    where('keywords', 'array-contains', term ?? ''),
    limit(ITEMS_PER_PAGE),
    orderBy('createdAt', 'desc')
  );
  const peopleQuery = useFirestoreInfiniteQuery<IPerson>(['people', { term }], approvedPeople, (snapshot) => {
    const lastDocument = snapshot.docs[snapshot.docs.length - 1];

    // Fetch the next documents starting after the last document fetched.
    if (lastDocument?.exists()) {
      return query(approvedPeople, startAfter(lastDocument ?? 0));
    }

    // Returning undefined means we don't have any more documents to fetch.
    return;
  });

  // Detects scroll to the bottom and triggers a refetch of the next page of people.
  useFetchNextPage(peopleQuery);

  // Preparing peopleToDisplay array by formatting the data.
  const peopleToDisplay = [];
  if (peopleQuery?.data?.pages.length) {
    const formattedResult = peopleQuery.data?.pages.map((page) => page.docs.map((doc) => doc.data()));
    for (const innerArray of formattedResult) {
      for (const item of innerArray) {
        peopleToDisplay.push(item);
      }
    }
  }

  return (
    <Container maxWidth="xl">
      {peopleQuery.isLoading ? (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <CircularProgress sx={{ marginTop: '4rem', alignSelf: 'center' }} />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {peopleQuery.data?.pages.length! === 0 ? (
            <Paper sx={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }} elevation={0}>
              <Typography variant="h4">This name doesn’t appear in our database.</Typography>
            </Paper>
          ) : fullWidth ? (
            <Typography variant="h6">Search Results</Typography>
          ) : (
            <Typography variant="h6">Recent Terror Supporters</Typography>
          )}
          <Grid container spacing={2}>
            {peopleToDisplay.length > 0 &&
              peopleToDisplay.map((person: IPerson) => {
                if (!school || (school && person.school === school)) {
                  return (
                    <Grid
                      display="flex"
                      item
                      key={person.id}
                      {...(fullWidth ? { xs: 12 } : { xs: 12, sm: 12, md: 6, lg: 4 })}
                    >
                      <PersonCard person={person} />
                    </Grid>
                  );
                }

                return null;
              })}
          </Grid>
        </Box>
      )}
    </Container>
  );
}
