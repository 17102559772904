import { Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import Layout from './Layout';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Admin from './Admin';
import Profile from './Profile/Profile';
import Search from './Search/Search';
import Report from './Report';
import AdminEdit from './AdminEdit';

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="tos" element={<TermsOfService />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="admin" element={<Admin />} />
        <Route path="admin/edit/:id" element={<AdminEdit />} />
        <Route path="profile/:id" element={<Profile />} />
        <Route path="search" element={<Search />} />
        <Route path="report" element={<Report />} />

        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
