import { SentimentVeryDissatisfied } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import * as React from 'react';

export default function SupportChip({ label }: { label: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        color: '#F03D3D',
        backgroundColor: '#FFEAEA',
        borderRadius: 1,
        paddingY: 0.5,
        paddingX: 1
      }}
    >
      <SentimentVeryDissatisfied fontSize="small" />
      <Typography sx={{ fontSize: 12, textTransform: 'uppercase' }}>{label}</Typography>
    </Box>
  );
}
