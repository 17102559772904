import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import React from 'react';

export default function Footer() {
  return (
    <Box sx={{ backgroundColor: '#D8DDEA', display: 'flex', gap: 3, padding: 3, justifyContent: 'center', mt: 5 }}>
      <NavLink to="/tos">Terms of Service</NavLink>
      <NavLink to="/privacy">Privacy Policy</NavLink>
    </Box>
  );
}
