import { collection, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { firestore } from './firebase';
import dayjs, { Dayjs } from 'dayjs';

export interface IPost {
  type: 'screenshot' | 'link' | '';
  link: string;
  isDeleted: boolean;
}

export interface IPerson {
  id?: string;
  approvalReason: string;
  firstName: string;
  lastName: string;
  photo: string;
  description: string;
  email?: string;
  phone?: string;
  faculty?: string;
  facebook_url?: string;
  linkedin_url?: string;
  twitter_url?: string;
  instagram_url?: string;
  tiktok_url?: string;
  tag: string;
  school: string;
  status: string;
  posts: IPost[];
  approved: boolean;
  createdAt?: Dayjs | '';
  updatedAt?: Dayjs | '';
  updatedBy?: string;
  isReviewed?: boolean;
  keywords: string[];
}

export interface IUniversity {
  name: string;
}

export const personConverter = {
  toFirestore(item: IPerson): DocumentData {
    return {
      approvalReason: item.approvalReason ?? '',
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
      photo: item.photo ?? '',
      description: item.description ?? '',
      email: item.email ?? '',
      phone: item.phone ?? '',
      faculty: item.faculty ?? '',
      facebook_url: item.facebook_url ?? '',
      linkedin_url: item.linkedin_url ?? '',
      twitter_url: item.twitter_url ?? '',
      instagram_url: item.instagram_url ?? '',
      tiktok_url: item.tiktok_url ?? '',
      tag: item.tag ?? '',
      school: item.school ?? '',
      status: item.status ?? '',
      posts: item.posts ?? '',
      approved: item.approved ?? '',
      createdAt: item.createdAt ? item.createdAt.toDate() : '',
      updatedAt: item.updatedAt ? item.updatedAt.toDate() : '',
      updatedBy: item.updatedBy ?? '',
      isReviewed: item.isReviewed ?? false,
      keywords: item.keywords ?? []
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IPerson {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      approvalReason: data?.approvalReason,
      firstName: data?.firstName,
      lastName: data?.lastName,
      photo: data?.photo,
      description: data?.description,
      email: data?.email,
      phone: data?.phone,
      faculty: data?.faculty,
      facebook_url: data?.facebook_url,
      linkedin_url: data?.linkedin_url,
      twitter_url: data?.twitter_url,
      instagram_url: data?.instagram_url,
      tiktok_url: data?.tiktok_url,
      tag: data?.tag,
      school: data?.school,
      status: data?.status,
      posts: data?.posts ?? [],
      approved: data?.approved,
      createdAt: data?.createdAt ? dayjs(new Date(data?.createdAt.seconds * 1000)) : undefined,
      updatedAt: data?.updatedAt ? dayjs(new Date(data?.updatedAt.seconds * 1000)) : undefined,
      updatedBy: data.updatedBy ?? '',
      isReviewed: data.isReviewed ?? false,
      keywords: data.keywords ?? []
    };
  }
};

export const universitiesConverter = {
  toFirestore(item: IUniversity): DocumentData {
    return {
      name: item.name
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IUniversity {
    const data = snapshot.data(options);
    return {
      name: data?.name
    };
  }
};

export const peopleCollection = collection(firestore, 'people').withConverter(personConverter);
export const universitiesCollection = collection(firestore, 'universities').withConverter(universitiesConverter);
