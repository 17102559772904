import { v4 } from 'uuid';
import { useConfirm } from 'material-ui-confirm';
import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';

export default function useFileUpload() {
  const confirm = useConfirm();

  const uploadFile = async (file: File) => {
    const storage = getStorage();

    if (!file.type.includes('image')) {
      await confirm({
        title: 'Only images are supported',
        description:
          'File uploads are not supported. You can copy and paste an image, and it will appear as a comment on a work item.'
      });
      return;
    }

    const fileRef = ref(storage, `files/${v4()}`);
    await uploadBytes(fileRef, file);
    const url = await getDownloadURL(fileRef);

    return url;
  };

  return { uploadFile };
}
