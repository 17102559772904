import { School } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function SchoolChip({ label }: { label: string }) {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', color: '#324370' }}>
      <School fontSize="small" />
      <Typography sx={{ fontSize: 12, textTransform: 'uppercase' }}>{label}</Typography>
    </Box>
  );
}
