import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, MenuItem, Container, Divider } from '@mui/material';
import useFileUpload from '../hooks/useFileUpload';
import dayjs from 'dayjs';
import { IPost, IUniversity, peopleCollection, universitiesCollection } from '../utils/data';
import { addDoc, query } from 'firebase/firestore';
import DragDrop from '../components/DragDrop/DragDrop';
import { useNavigate } from 'react-router-dom';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { generateKeywords } from '../utils/generateKeywords';

function MyForm() {
  const navigate = useNavigate();
  const { uploadFile } = useFileUpload();
  const getAllUniversitiesQuery = query(universitiesCollection);
  const universitiesQuery = useFirestoreQueryData<IUniversity>(['universities'], getAllUniversitiesQuery, {
    subscribe: true
  });
  const [posts, setPosts] = useState<IPost[]>([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    school: '',
    status: '',
    upload_type: 'screenshot',
    tag: 'Hamas Supporter',
    file: '',
    phone: '',
    photo: '',
    description: '',
    facebook_url: '',
    linkedin_url: '',
    twitter_url: '',
    instagram_url: '',
    tiktok_url: '',
    approved: false,
    social_media_posts: ['']
  });

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSocialMediaPostsChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const newSocialMediaPosts = [...formData.social_media_posts];
    if (value !== '' && newSocialMediaPosts.length === index + 1) {
      newSocialMediaPosts.push('');
    }
    if (value === '' && newSocialMediaPosts[index + 1] === '' && newSocialMediaPosts.length === index + 2) {
      newSocialMediaPosts.pop();
    }
    newSocialMediaPosts[index] = value;
    setFormData({ ...formData, [name]: newSocialMediaPosts });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    let postsWithLink: Array<IPost> = [];
    if (formData.social_media_posts.length === 0) {
      postsWithLink = posts;
    }
    postsWithLink = formData.social_media_posts
      .filter((singlePost) => singlePost !== '')
      .map((singlePost) => ({ type: 'link', link: singlePost, isDeleted: false }));

    for (let i = 0; i < posts.length; i++) {
      postsWithLink.push(posts[i]);
    }

    const data = {
      approvalReason: '',
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      school: formData.school,
      status: formData.status,
      phone: formData.phone,
      description: formData.description,
      facebook_url: formData.facebook_url,
      linkedin_url: formData.linkedin_url,
      twitter_url: formData.twitter_url,
      instagram_url: formData.instagram_url,
      tiktok_url: formData.tiktok_url,
      tag: formData.tag,
      photo: formData.photo,
      posts: postsWithLink,
      approved: formData.approved,
      createdAt: dayjs(),
      isReviewed: false,
      keywords: generateKeywords(formData.firstName, formData.lastName)
    };
    // Handle form submission here
    await addDoc(peopleCollection, data);

    alert('Profile uploaded successfully!');

    navigate('/');
  };

  // Used for uploading a profile picture.
  // const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
  //   if (!e.target) {
  //     return;
  //   }
  //   const { files, name } = e.target;

  //   if (!files) {
  //     console.log('no file');
  //     return;
  //   }

  //   const file = await uploadFile(files[0]);
  //   if (!file) {
  //     console.log('file upload failed');
  //     return;
  //   }
  //   setFormData({ ...formData, [name]: file });
  // };

  return (
    <Container maxWidth="lg" sx={{ pt: 5 }}>
      <Typography textAlign="center" variant="h3" component="div" color="#362B2B" fontWeight="500" mb={2.5}>
        Encountered{' '}
        <Typography display="inline" variant="h3" color="#E04F5F" fontWeight="500">
          Terrorism Supporter?
        </Typography>
      </Typography>
      <Typography textAlign="center" color="#3D3D3D" fontSize={20} fontWeight="400" mb={3.875}>
        Help maintain a safe environment for everyone
      </Typography>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h4" component="div" fontWeight="600" gutterBottom color="primary">
              Report a case
            </Typography>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
              <Typography fontSize={16} fontWeight="400" component="div" gutterBottom>
                Add profile, description, and evidence of the terror supporting act
              </Typography>

              <Divider variant="inset" sx={{ margin: 0 }} />

              <Typography fontWeight="600" variant="h6" component="div">
                Terror supporter profile
              </Typography>
              <Grid container spacing={2}>
                <Grid xs={6} sx={{ paddingLeft: 2.5 }}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="standard"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid xs={6} sx={{ paddingLeft: 2.5 }}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="standard"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  select
                  variant="standard"
                  name="school"
                  value={formData.school}
                  onChange={handleInputChange}
                  label="School Name"
                >
                  <MenuItem value="">
                    <em>{universitiesQuery.isLoading ? 'Loading ...' : 'Other'}</em>
                  </MenuItem>
                  {universitiesQuery?.data?.map((university) => (
                    <MenuItem key={university.name} value={university.name}>
                      {university.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  select
                  variant="standard"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  label="School Status"
                >
                  <MenuItem value="">
                    <em>Unknown</em>
                  </MenuItem>
                  <MenuItem value="student">
                    <em>Student</em>
                  </MenuItem>
                  <MenuItem value="faculty">
                    <em>Faculty</em>
                  </MenuItem>
                  <MenuItem value="alumni">
                    <em>Alumni</em>
                  </MenuItem>
                </TextField>
              </Grid>

              <TextField
                fullWidth
                label="Description"
                variant="standard"
                name="description"
                placeholder="describe the act in 1-3 sentences in English"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleInputChange}
              />

              <Typography variant="h6" component="div">
                Evidence - Photo, file or link
              </Typography>
              <Typography color="#3D3D3D">Link to social media post or upload an image or a document</Typography>

              <TextField
                fullWidth
                label="Link to social media post"
                variant="standard"
                value={formData.social_media_posts[0]}
                name="social_media_posts"
                onChange={(e) => handleSocialMediaPostsChange(e, 0)}
              />
              {formData.social_media_posts.slice(1).map((postItem, index) => (
                <TextField
                  key={index}
                  fullWidth
                  label="Link to social media post"
                  variant="standard"
                  name="social_media_posts"
                  value={postItem}
                  onChange={(e) => handleSocialMediaPostsChange(e, index + 1)}
                />
              ))}

              <Typography variant="caption">Upload Photo</Typography>
              <DragDrop posts={posts} setPosts={setPosts} />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Facebook Profile"
                    variant="standard"
                    name="facebook_url"
                    value={formData.facebook_url}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="LinkedIn Profile"
                    variant="standard"
                    name="linkedin_url"
                    value={formData.linkedin_url}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Twitter Profile"
                    variant="standard"
                    name="twitter_url"
                    value={formData.twitter_url}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Instagram Profile"
                    variant="standard"
                    name="instagram_url"
                    value={formData.instagram_url}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="TikTok Profile"
                    variant="standard"
                    name="tiktok_url"
                    value={formData.tiktok_url}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Button type="submit" variant="contained" color="primary" fullWidth size="large">
                Send
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MyForm;
