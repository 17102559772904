import { Box, Container, Typography } from '@mui/material';
import Directory from '../../components/Directory/Directory';
import SearchBar from '../../components/SearchBar';
import { useSearchParams } from 'react-router-dom';
import './Search.css';

export default function Search() {
  const [params] = useSearchParams();

  const term = params.get('term');
  const school = params.get('school');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Typography
        variant="h3"
        sx={{
          textAlign: 'center',
          mt: 5,
          marginTop: '90px',
          color: '#362B2B',
          fontFamily: 'Inter',
          fontSize: '56px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '64px',
          letterSpacing: '-0.56px'
        }}
      >
        Find US Students Who <b style={{ color: '#DE1F1F' }}>Support Terrorism</b>
      </Typography>
      <span className="subtitle__Search">Prevent from employing individuals who openly support terror.</span>
      <Container maxWidth="md">
        <SearchBar term={term || ''} schoolTerm={school || ''} page="main" />
      </Container>
      <Directory fullWidth />
    </Box>
  );
}
