import { Container, Typography } from '@mui/material';

export default function TermsOfService() {
  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 5 }}>
      <Typography variant="h5">Terms of Service</Typography>
      <p>By using our website, you agree to the following terms and conditions:</p>
      <ul>
        <li>
          All content provided on this website is for informational purposes only. We make no representations as to the
          accuracy or completeness of any information on this site.
        </li>
        <li>
          You agree to use the information provided here responsibly and ethically. Misuse can result in legal
          consequences.
        </li>
        <li>
          We are not responsible for any losses, injuries, or damages from the display or use of this information.
        </li>
        <li>We reserve the right to change, update, or remove content at any time without prior notice.</li>
        <li>
          Any content, comment, or post that is found to be defamatory, offensive, or hateful will be removed and the
          user might be banned.
        </li>
      </ul>
    </Container>
  );
}
