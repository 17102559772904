const createKeywords = (name: string) => {
  const arrName = [];
  for (let i = 0; i < name.length; i++) {
    for (let j = i + 1; j <= name.length; j++) {
      arrName.push(name.slice(i, j));
    }
  }
  return arrName;
};

export const generateKeywords = (firstName: string, lastName: string) => {
  const keywordFullName = createKeywords(`${firstName} ${lastName}`);
  const keywordLastNameFirst = createKeywords(`${lastName} ${firstName}`);
  return Array.from(new Set(['', ...keywordFullName, ...keywordLastNameFirst]));
};
