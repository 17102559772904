import { Box, Button, Card, CardActions, CardContent, CardMedia, IconButton, Link, Typography } from '@mui/material';
import * as React from 'react';
import { IPost } from '../utils/data';
import { OpenInNew } from '@mui/icons-material';

export default function LinkPreview({ post }: { post: IPost }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ flex: 1, display: 'flex' }}>
          <Link
            href={post.link}
            target="_blank"
            style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 0 }}
          >
            {post.link}
          </Link>
        </Typography>
        <IconButton component={Link} href={post.link} target="_blank">
          <OpenInNew fontSize="small" />
        </IconButton>
      </Box>

      <Link href={post.link} target="_blank">
        <img src={`https://image.thum.io/get/${post.link}`} style={{ width: '100%' }} />
      </Link>
    </Box>
  );
}
