import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IPerson, peopleCollection } from '../utils/data';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { Box, Button, CircularProgress, Paper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Check, Close } from '@mui/icons-material';
import NoProfileSVG from '../assets/icons/no-profile.svg';
import { query, orderBy, where, Query } from 'firebase/firestore';
import SearchBar from './SearchBar';

export default function AdminDirectory({ onlyShowToReview }: { onlyShowToReview: boolean }) {
  const [params] = useSearchParams();
  const term = params.get('term');

  const navigate = useNavigate();
  let q: Query<IPerson>;
  if (onlyShowToReview) {
    q = query(peopleCollection, where('isReviewed', '==', false), orderBy('createdAt', 'desc'));
  } else {
    q = query(peopleCollection, orderBy('createdAt', 'desc'));
  }

  const peopleQuery = useFirestoreQueryData<IPerson>(['admin_people', onlyShowToReview], q, {
    subscribe: true
  });

  const columns: GridColDef[] = [
    {
      field: 'photo',
      headerName: '',
      width: 80,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundImage: `url(${params.value}), url(${NoProfileSVG})`,
            width: 40,
            height: 40,
            backgroundSize: 'cover',
            borderRadius: 20,
            backgroundPosition: 'center'
          }}
        />
      )
    },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: true
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: true
    },
    {
      field: 'school',
      headerName: 'School',
      width: 160,
      editable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      editable: false
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 100,
      editable: false
    },

    {
      field: 'twitter_url',
      headerName: 'Twitter',
      renderCell: (params) => {
        if (params.value === '')
          return (
            <Close
              style={{
                color: 'red'
              }}
            />
          );

        return <a href={params.value}>Link</a>;
      }
    },
    {
      field: 'facebook_url',
      headerName: 'Facebook',
      renderCell: (params) => {
        if (params.value === '')
          return (
            <Close
              style={{
                color: 'red'
              }}
            />
          );

        return <a href={params.value}>Link</a>;
      }
    },
    {
      field: 'instagram_url',
      headerName: 'Instagram',
      renderCell: (params) => {
        if (params.value === '')
          return (
            <Close
              style={{
                color: 'red'
              }}
            />
          );

        return <a href={params.value}>Link</a>;
      }
    },
    {
      field: 'tiktok_url',
      headerName: 'TikTok',
      renderCell: (params) => {
        if (params.value === '')
          return (
            <Close
              style={{
                color: 'red'
              }}
            />
          );

        return <a href={params.value}>Link</a>;
      }
    },
    {
      field: 'approved',
      headerName: 'Approved',
      renderCell: (params) => {
        return params.value ? (
          <Check
            style={{
              color: 'green'
            }}
          />
        ) : (
          <Close
            style={{
              color: 'red'
            }}
          />
        );
      }
    },
    {
      field: 'isReviewed',
      headerName: 'Reviewed',
      renderCell: (params) => {
        return params.value ? (
          <Check
            style={{
              color: 'green'
            }}
          />
        ) : (
          <Close
            style={{
              color: 'red'
            }}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`edit/${params.row.id}`);
            }}
          >
            Edit
          </Button>
        );
      }
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <SearchBar page="admin" />
      <Paper sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {peopleQuery.isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid<IPerson>
            autoHeight
            sx={{ flex: 1 }}
            rows={
              peopleQuery.data?.filter(
                (person) =>
                  !term ||
                  (term && person.firstName.toLowerCase().includes(term.toLowerCase())) ||
                  (term && person.lastName.toLowerCase().includes(term.toLowerCase())) ||
                  (term && person.school.toLowerCase().includes(term.toLowerCase()))
              ) ?? []
            }
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              }
            }}
            // pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        )}
      </Paper>
    </Box>
  );
}
