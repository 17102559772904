import { Box, Container, Paper, Typography } from '@mui/material';
import Directory from '../../components/Directory/Directory';
import SearchBar from '../../components/SearchBar';
import './Home.css';

export default function Home() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 10, gap: 3 }}>
      <Typography
        variant="h3"
        sx={{
          textAlign: 'center',
          color: '#362B2B',
          fontSize: '70px',
          fontWeight: '500',
          letterSpacing: '-2px',
          px: 1
        }}
      >
        Find US Students Who <b style={{ color: '#DE1F1F' }}>Support Terrorism</b>
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        Prevent from employing individuals who openly support terror.
      </Typography>
      <Container maxWidth="md" style={{ marginTop: '47px' }}>
        <SearchBar page="main" />
      </Container>
      <Container maxWidth="xl">
        <Paper>
          <Typography variant="h4" sx={{ textAlign: 'center', py: 10, my: 10 }}>
            Coming soon
          </Typography>
        </Paper>
      </Container>

      {/* <Directory /> */}
    </Box>
  );
}
