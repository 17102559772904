const facebookUrlPattern = /https:\/\/(www\.)?facebook\.com\/.+/i;
const twitterPattern = /^(https?:\/\/)?(www\.)?x\.com\/[A-Za-z0-9_]+(\/status\/\d+(\?s=\d+)?)?$/;
const oldTwitterPattern = /https?:\/\/twitter\.com\/[A-Za-z0-9_]+\/status\/\d+/;
const instagramUrlPattern = /https?:\/\/www\.instagram\.com\/p\/[A-Za-z0-9_-]+/;
const tiktokUrlPattern = /https?:\/\/www\.tiktok\.com\/@[^/]+\/video\/\d+/;
const youtubeUrlPattern = /https?:\/\/(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|user\/[^/]+|channel\/[^/]+|[^/?]+)([^&\n#?]+)/;



export const isTwitterLink = (link: string) => {
    return (twitterPattern.test(link) || oldTwitterPattern.test(link));
}

export const isInstagramLink = (link: string) => {
    return instagramUrlPattern.test(link);
}

export const isYoutubeLink = (link: string) => {
    return (youtubeUrlPattern.test(link));

}
export const isFacebookLink = (link: string) => {
    return facebookUrlPattern.test(link);
}
export const isTikTokLink = (link: string) => {
    return (tiktokUrlPattern.test(link));
}
