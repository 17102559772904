import { Container, Link, Typography } from '@mui/material';

export default function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 5 }}>
      <Typography variant="h5">Privacy Policy</Typography>

      <Typography variant="body1" paragraph>
        Effective Date: October 20, 2023
      </Typography>

      <Typography variant="body1" paragraph>
        This Privacy Policy describes how Don’t Hire Hate collects, processes, shares, and safeguards personal
        information. Don’t Hire Hate is a media and data platform that aims to shine light on university students and
        faculty who publicly support acts of terror and condemn Israel for defending itself.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Personal Information We Collect
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Publicly Available Information.</strong> In connection with operating our website, we collect publicly
        available personal information from social media and other content platforms; this information may include
        names, professional or educational affiliations and content with which the individual is associated.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Personal Information We Automatically Collect.</strong> We may automatically receive and store certain
        personal information about individuals who interact with our website through their browser or device. We and our
        service providers may use a variety of technologies, including cookies, to assist in collecting this
        information. Specifically, we collect technical information associated with the device(s) you use to access our
        website, including information related to your browser and operating system, IP address (the Internet address of
        your computer), unique device identifiers, and other information such as your device type.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How We Use Personal Information
      </Typography>

      <Typography variant="body1" paragraph>
        We use personal information to operate our website. We may also use personal information to comply with our
        legal obligations and protect ourselves against legal claims or disputes.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How We Disclose Personal Information
      </Typography>

      <Typography variant="body1" paragraph>
        We may disclose personal information to the following recipients and in the following circumstances:
      </Typography>

      <Typography variant="body2" paragraph>
        <strong>Public:</strong> We disclose the personal information we collect on our website, making that information
        publicly accessible.
      </Typography>

      <Typography variant="body2" paragraph>
        <strong>Service Providers:</strong> We rely on vendors and service providers, such as providers of hosting and
        cloud computing services, other IT services, and other administrative services. These third parties may have
        access to or process personal information as part of providing these services.
      </Typography>

      <Typography variant="body2" paragraph>
        <strong>Legal:</strong> We may disclose personal information to law enforcement agencies, regulatory bodies,
        public authorities or pursuant to the exercise of legal proceedings if we are legally required to do so, or if
        we believe, in good faith, that such disclosure is necessary to comply with a legal obligation or request, to
        enforce our terms and conditions, to prevent or resolve security or technical issues, or to protect the rights,
        property or safety of others.
      </Typography>

      <Typography variant="body2" paragraph>
        <strong>Business Transactions:</strong> We may sell, transfer or otherwise share personal information in
        connection with a merger, acquisition or asset sale, financing due diligence, reorganization, bankruptcy,
        receivership, sale of company assets, or transition of service to another provider, including as part of any due
        diligence process.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Privacy Rights and Choices
      </Typography>

      <Typography variant="body1" paragraph>
        To the extent required by law, we offer individuals the following rights, provided that we do not offer such
        rights with respect to any information that is publicly available outside this platform:
      </Typography>

      <Typography variant="body2" paragraph>
        - Access the personal information we hold about them;
        <br />
        - Request that we correct any inaccurate personal information we hold about them;
        <br />
        - Request that we delete any personal information we hold about them;
        <br />
        - Restrict the processing of the personal information we hold about them;
        <br />
        - Object to the processing of personal information we hold about them;
        <br />- Receive any personal information we hold about them in a structured and commonly used machine readable
        format or have such personal information transmitted to another company;
      </Typography>

      <Typography variant="body1" paragraph>
        Please note that we may require individuals to verify their identity before we respond to their privacy request.
      </Typography>

      <Typography variant="body1" paragraph>
        To submit a request, please contact us using the details in the “Contact Us” section below.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Retention of Personal Information
      </Typography>

      <Typography variant="body1" paragraph>
        We retain personal information only for as long as is necessary to fulfil the purposes for which it was
        collected and processed, in accordance with our retention procedures, and in accordance with applicable laws.
      </Typography>

      <Typography variant="body1" paragraph>
        To determine the appropriate retention period for personal information, we consider the amount, nature, and
        sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of
        personal information, the purposes for which we use personal information and whether we can achieve those
        purposes through other means, and the applicable legal requirements.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Security of Personal Information
      </Typography>

      <Typography variant="body1" paragraph>
        We use reasonable organizational, technical and administrative measures designed to protect against unauthorized
        access, misuse, loss, disclosure, alteration, and destruction of personal information we process. Unfortunately,
        data transmission over the Internet cannot be guaranteed as completely secure. Therefore, while we strive to
        protect personal information, we cannot guarantee its security.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Links to Other Sites
      </Typography>

      <Typography variant="body1" paragraph>
        Our website may contain links to other sites that are not operated by us. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any third party sites or services.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Changes and Updates to this Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        We will update this policy from time to time. Changes to this policy are effective when they are posted on this
        page.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>

      <Typography variant="body1" paragraph>
        To submit questions or comments about this policy, our privacy practices, or to exercise privacy rights, please
        contact us by email at <Link href="mailto:contact@donthirehate.com">contact@donthirehate.com</Link>.
      </Typography>
    </Container>
  );
}
