import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPerson } from '../utils/data';
import SchoolChip from './SchoolChip';
import SocialLinks from './SocialLinks';
import SupportChip from './SupportChip';
import NoProfileSVG from '../assets/icons/no-profile.svg';

export default function PersonCard({ person }: { person: IPerson }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isInProfilePage = location.pathname.includes('/profile');

  return (
    <Paper
      sx={{
        flex: 1,
        overflowX: 'hidden',
        padding: 2,
        borderRadius: 2,
        display: 'flex',
        gap: 3,
        border: '1px solid #ddd',
        cursor: isInProfilePage ? 'default' : 'pointer',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
      }}
      elevation={0}
      onClick={() => navigate(`/profile/${person.id}`)}
    >
      <Box
        sx={{
          backgroundImage: `url(${person.photo}), url(${NoProfileSVG})`,
          width: 120,
          height: 150,
          backgroundSize: 'cover',
          borderRadius: 3,
          backgroundPosition: 'center'
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', flex: 1 }}>
        {person.school && <SchoolChip label={`${person.school} ${person.status}`} />}
        <Typography variant="h6" sx={{ fontWeight: '700' }}>
          {person.firstName} {person.lastName}
        </Typography>
        <Typography
          sx={{
            webkitLineClamp: '2',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            height: '3em'
          }}
        >
          {person.description}
        </Typography>
        {person.tag && <SupportChip label={person.tag} />}
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ flexGrow: 1 }}>
            <SocialLinks person={person} />
          </Box>
          {/*<Box>*/}
          {/*  <NavLink to={`/profile/${person.id}`}>Full Profile &gt;</NavLink>*/}
          {/*</Box>*/}
        </Box>
      </Box>
    </Paper>
  );
}
