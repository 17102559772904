import { Box, Breadcrumbs, Button, CircularProgress, Container, Typography } from '@mui/material';
import AdminDirectoryEdit from '../components/AdminDirectoryEdit/AdminDirectoryEdit';
import { auth } from '../utils/firebase';
import { useAuthUser } from '@react-query-firebase/auth';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { deleteDoc, doc } from 'firebase/firestore';
import { peopleCollection } from '../utils/data';
import { useConfirm } from 'material-ui-confirm';

export default function AdminEdit() {
  const confirmDialog = useConfirm();
  const navigate = useNavigate();
  const { id } = useParams();
  const userQuery = useAuthUser(['user'], auth, {
    onSuccess: (data) => {
      if (data === null) {
        navigate('/admin');
      }
    },
    onError: () => {
      navigate('/admin');
    }
  });

  if (userQuery.isLoading) {
    return <CircularProgress />;
  }

  const deleteProfile = async () => {
    try {
      await confirmDialog({
        title: 'Delete Profile',
        description: 'Are you sure you want to delete this profile?'
      });
    } catch {
      return;
    }

    await deleteDoc(doc(peopleCollection, id));
    navigate('/admin');
  };

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Breadcrumbs sx={{ flex: 1 }}>
          <Typography variant="h5" component={Link} to={'/admin'}>
            Admin
          </Typography>
          <Typography variant="h5">Edit</Typography>
        </Breadcrumbs>
        <Button variant="contained" color="primary" component={Link} to={`/profile/${id}`}>
          Profile Page
        </Button>
        <Button variant="contained" color="secondary" onClick={deleteProfile}>
          Delete Profile
        </Button>
      </Box>

      <AdminDirectoryEdit />
    </Container>
  );
}
