import { IPerson, IPost } from '../utils/data';
import { Box, BoxProps, Grid, Link, Paper, Typography } from '@mui/material';
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, TwitterEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { isFacebookLink, isInstagramLink, isTikTokLink, isTwitterLink, isYoutubeLink } from '../utils/text.utils';
import React from 'react';
import PersonCardInPost from './PersonCardInPost';
import { generateKey } from 'crypto';
import LinkPreview from './LinkPreview';

export default function LinkPost({ post, person, ...props }: { post: IPost; person?: IPerson } & BoxProps) {
  if (!person || !post.link) {
    return null;
  }
  const displayLinkPlatforms = (link: string) => {
    let genericLink = false;
    let SocialMediaComponent = () => {
      return <></>;
    };
    if (isTwitterLink(link)) {
      SocialMediaComponent = () => <TwitterEmbed url={post.link} />;
    } else if (isInstagramLink(link)) {
      SocialMediaComponent = () => <InstagramEmbed url={post.link} />;
    } else if (isFacebookLink(link)) {
      SocialMediaComponent = () => <FacebookEmbed url={post.link} />;
    } else if (isYoutubeLink(link)) {
      SocialMediaComponent = () => <YouTubeEmbed url={post.link} />;
    } else if (isTikTokLink(link)) {
      SocialMediaComponent = () => <TikTokEmbed url={post.link} />;
    } else {
      genericLink = true;
    }
    return {
      generic: genericLink,
      component: (
        <Box {...props}>
          <SocialMediaComponent />
        </Box>
      )
    };
  };

  const { component, generic } = displayLinkPlatforms(post.link);

  return (
    <Grid container>
      <Grid item xs={12}>
        {generic ? <LinkPreview post={post} /> : component}
      </Grid>
    </Grid>
  );
}
