import { IPost, IUniversity, peopleCollection, universitiesCollection } from '../../utils/data';
import {
  useFirestoreDocument,
  useFirestoreDocumentMutation,
  useFirestoreQueryData
} from '@react-query-firebase/firestore';
import { Box, Button, CircularProgress, Grid, MenuItem, Paper, Switch, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, query } from 'firebase/firestore';
import Post from '../Post';
import { Dispatch, SetStateAction, useState } from 'react';
import NoProfileSVG from '../../assets/icons/no-profile.svg';
import dayjs, { Dayjs } from 'dayjs';
import { auth } from '../../utils/firebase';
import { FileUploader } from 'react-drag-drop-files';
import useFileUpload from '../../hooks/useFileUpload';
import './AdminDirectoryEdit.css';
import { fileTypes } from '../../utils/fileTypes';
import { generateKeywords } from '../../utils/generateKeywords';

export default function AdminDirectoryEdit() {
  const navigate = useNavigate();
  const { uploadFile } = useFileUpload();
  const { id } = useParams();

  const getAllUniversitiesQuery = query(universitiesCollection);
  const universitiesQuery = useFirestoreQueryData<IUniversity>(['universities'], getAllUniversitiesQuery, {
    subscribe: true
  });

  const ref = doc(peopleCollection, id);

  const updatePersonMutation = useFirestoreDocumentMutation(
    ref,
    { merge: true },
    {
      onSuccess: () => {
        navigate('/admin');
      }
    }
  );

  const personDocument = useFirestoreDocument(
    ['people', id],
    ref,
    {},
    {
      cacheTime: 0,
      onSuccess: (response) => {
        const data = response.data();
        if (data) {
          setPhoto(data.photo);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setDescription(data.description);
          setTag(data.tag);
          setSchool(data.school);
          setStatus(data.status);
          setPosts(data.posts);
          setApproved(data.approved);
          setEmail(data.email ?? '');
          setPhone(data.phone ?? '');
          setFaculty(data.faculty ?? '');
          setInstagramUrl(data.instagram_url ?? '');
          setTikTokUrl(data.tiktok_url ?? '');
          setFacebookUrl(data.facebook_url ?? '');
          setTwitterUrl(data.twitter_url ?? '');
          setLinkedinUrl(data.linkedin_url ?? '');
          setCreatedAt(data.createdAt ?? '');
          setIsReviewed(data.isReviewed ?? false);
          setApprovalReason(data.approvalReason ?? '');
        }
      }
    }
  );

  const person = personDocument.data;

  const [photo, setPhoto] = useState('');
  const [approvalReason, setApprovalReason] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [description, setDescription] = useState('');
  const [tag, setTag] = useState('');
  const [school, setSchool] = useState('');
  const [status, setStatus] = useState('');
  const [approved, setApproved] = useState(false);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [faculty, setFaculty] = useState('');
  const [instagram_url, setInstagramUrl] = useState('');
  const [tiktok_url, setTikTokUrl] = useState('');
  const [facebook_url, setFacebookUrl] = useState('');
  const [twitter_url, setTwitterUrl] = useState('');
  const [linkedin_url, setLinkedinUrl] = useState('');
  const [createdAt, setCreatedAt] = useState<Dayjs | ''>('');
  const [isReviewed, setIsReviewed] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isProfilePicLoading, setIsProfilePicLoading] = useState(false);
  const [isScreenShotDropLoading, setIsScreenShotDropLoading] = useState(false);

  const handleChange = async (file: File) => {
    setIsProfilePicLoading(true);
    try {
      const fileLink = await uploadFile(file);
      if (!fileLink) {
        return;
      }

      setPhoto(fileLink);
    } catch {
    } finally {
      setIsProfilePicLoading(false);
    }
  };
  const handleScreenShotDrop = async (file: File, index: number) => {
    setIsScreenShotDropLoading(true);
    try {
      const fileLink = await uploadFile(file);
      if (!fileLink) {
        return;
      }

      setPosts((prev) => {
        const temp = [...prev];
        temp[index].link = fileLink;
        return temp;
      });
    } catch {
    } finally {
      setIsScreenShotDropLoading(false);
    }
  };

  const handleRemoveOrRestorePost = (index: number, isDeleted: boolean) => {
    setPosts((prev) => {
      const temp = [...prev];
      temp[index] = { ...temp[index], isDeleted: !isDeleted };
      return temp;
    });
  };

  const handleEditPost = (index: number) => {
    setIsEditMode(true);
    setPosts((prev) => {
      const temp = [...prev];
      // Remove type so we can edit.
      temp[index] = { ...temp[index], type: '' };
      return temp;
    });
  };

  const handleSaveData = () => {
    if (isEditMode) {
      alert("Can't save while in edit post mode.");
      return;
    }
    updatePersonMutation.mutate({
      approvalReason,
      firstName,
      lastName,
      description,
      tag,
      school,
      status,
      photo,
      posts: posts.filter((item) => item.type !== ''),
      approved,
      email,
      phone,
      faculty,
      instagram_url,
      facebook_url,
      twitter_url,
      linkedin_url,
      tiktok_url,
      createdAt,
      updatedAt: dayjs(),
      updatedBy: auth.currentUser?.email ?? '',
      isReviewed,
      keywords: generateKeywords(firstName, lastName)
    });
  };

  const handleDeletePhoto = () => {
    setPhoto('');
  };

  if (personDocument.isLoading || person === undefined) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
      {/* Header */}
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${photo}), url(${NoProfileSVG})`,
                width: 150,
                height: 150,
                backgroundSize: 'cover',
                borderRadius: 75,
                backgroundPosition: 'center'
              }}
            >
              {isProfilePicLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <FileUploader
                  classes="file_uploader__AdminDirectoryEdit"
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
              )}
            </Box>
            <Box>
              <Button onClick={handleDeletePhoto} variant="outlined" color="secondary">
                Delete Photo
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography>Approved:</Typography>
                <Switch checked={approved} onChange={(e) => setApproved(e.target.checked)} />
              </Box>
              <TextField
                size="small"
                fullWidth
                placeholder="Approval Reason"
                label="Approval Reason"
                value={approvalReason}
                onChange={(e) => setApprovalReason(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="First Name"
                label={'First Name'}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="Last Name"
                label={'Last Name'}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                select
                name="school"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                label="School Name"
              >
                <MenuItem value="">
                  <em>{universitiesQuery.isLoading ? 'Loading ... ' : 'Other'}</em>
                </MenuItem>
                {universitiesQuery?.data?.map((university) => (
                  <MenuItem key={university.name} value={university.name}>
                    {university.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                select
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="School Status"
              >
                <MenuItem value="">
                  <em>Unknown</em>
                </MenuItem>
                <MenuItem value="student">
                  <em>Student</em>
                </MenuItem>
                <MenuItem value="faculty">
                  <em>Faculty</em>
                </MenuItem>
                <MenuItem value="alumni">
                  <em>Alumni</em>
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                placeholder="Email"
                label={'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="Phone"
                label={'Phone'}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="Faculty"
                label="Faculty"
                value={faculty}
                onChange={(e) => setFaculty(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            multiline
            rows={6}
            placeholder="Description"
            label={'Description'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="Instagram URL"
                label={'Instagram URL'}
                value={instagram_url}
                onChange={(e) => setInstagramUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="TikTok URL"
                label={'TikTok URL'}
                value={tiktok_url}
                onChange={(e) => setTikTokUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="Facebook URL"
                label={'Facebook URL'}
                value={facebook_url}
                onChange={(e) => setFacebookUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                placeholder="Twitter URL"
                label={'Twitter URL'}
                value={twitter_url}
                onChange={(e) => setTwitterUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="LinkedIn URL"
                label={'LinkedIn URL'}
                value={linkedin_url}
                onChange={(e) => setLinkedinUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="e.g Hamas Supporter"
                label={'Support Type'}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Typography sx={{ fontSize: '20px' }}>Posts</Typography>
          <Typography sx={{ fontSize: '20px' }}>{`${posts.length} in total`}</Typography>
        </Box>

        {posts.length === 0 ? (
          <Typography>No Posts</Typography>
        ) : (
          posts.map((post, index) => (
            <Box
              key={post.link}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: '10px',
                marginBottom: 2
              }}
            >
              {post.type === '' ? (
                <NewPost
                  isScreenShotDropLoading={isScreenShotDropLoading}
                  handleScreenShotDrop={(file: File) => {
                    handleScreenShotDrop(file, index);
                  }}
                  setIsEditMode={setIsEditMode}
                  data={{ link: post.link, isDeleted: post.isDeleted }}
                  setData={(data) =>
                    setPosts((prev) => {
                      const temp = [...prev];
                      temp[index] = data;
                      return temp;
                    })
                  }
                />
              ) : (
                <Post post={post} person={person.data()} sx={{ width: '100%' }} />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Button
                  onClick={() => handleRemoveOrRestorePost(index, post.isDeleted)}
                  variant="contained"
                  color={post.isDeleted ? 'warning' : 'error'}
                >
                  {post.isDeleted ? 'Restore Post' : 'Delete Post'}
                </Button>
                <Button onClick={() => handleEditPost(index)} variant="contained">
                  Edit Post
                </Button>
              </Box>
            </Box>
          ))
        )}

        <Box>
          <Button
            onClick={() => {
              setPosts((prev) => [...prev, { link: '', type: '', isDeleted: false }]);
            }}
            variant="outlined"
            color="primary"
            sx={{ mt: 3 }}
          >
            Add New Post
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1, alignItems: 'center' }}>
        <Button onClick={handleSaveData} variant="contained" sx={{ px: 5 }} size="large">
          Save
        </Button>
        <Button onClick={() => navigate(-1)} variant="outlined" sx={{ px: 5 }} size="large">
          Back
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>Reviewed:</Typography>
          <Switch checked={isReviewed} onChange={(e) => setIsReviewed(e.target.checked)} />
        </Box>
      </Box>
    </Paper>
  );
}

const NewPost = ({
  data,
  setData,
  setIsEditMode,
  handleScreenShotDrop,
  isScreenShotDropLoading
}: {
  data?: { link?: string; isDeleted?: boolean };
  setData: (obj: IPost) => any;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  handleScreenShotDrop: (file: File) => void;
  isScreenShotDropLoading: boolean;
}) => {
  const [link, setLink] = useState(data?.link ?? '');
  const [isDeleted, setIsDeleted] = useState(data?.isDeleted ?? false);
  const [type, setType] = useState<IPost['type']>('');
  return (
    <Box sx={{ marginTop: 5 }}>
      <Typography sx={{ marginBottom: '20px' }}>New Post</Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            size="small"
            fullWidth
            placeholder="Link"
            label={'Link'}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            sx={{ minWidth: 200, flex: 2 }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            size="small"
            fullWidth
            select
            name="type"
            value={type}
            onChange={(e) => setType(e.target.value as IPost['type'])}
            label="Type"
            sx={{ minWidth: 200, flex: 1 }}
          >
            <MenuItem value="link">
              <em>Link</em>
            </MenuItem>
            <MenuItem value="screenshot">
              <em>Screenshot</em>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>Deleted</Typography>
          <Switch checked={isDeleted} onChange={(e) => setIsDeleted(e.target.checked)} />
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              onClick={() => {
                if (type === '') {
                  alert('choose type');
                  return;
                }
                setIsEditMode(false);
                setData({ link, type, isDeleted });
              }}
              variant="contained"
            >
              Add Link
            </Button>
          </Box>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {isScreenShotDropLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ marginBottom: 2 }}>
              <FileUploader handleChange={handleScreenShotDrop} name="file" types={fileTypes} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
