import { Box, Button, FormControl, Grid, InputBase, InputLabel, MenuItem, Select } from '@mui/material';
import { School, Search } from '@mui/icons-material';
import { FormEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { IUniversity, universitiesCollection } from '../utils/data';

type SearchBarProps = {
  term?: string;
  schoolTerm?: string;
  page: 'main' | 'admin';
};

export default function SearchBar({ term, schoolTerm, page }: SearchBarProps) {
  const navigate = useNavigate();
  const getAllUniversitiesQuery = query(universitiesCollection, orderBy('name'));
  const universitiesQuery = useFirestoreQueryData<IUniversity>(['universities'], getAllUniversitiesQuery, {
    subscribe: true
  });
  const [school, setSchool] = useState<string>(schoolTerm ?? '');
  const [search, setSearch] = useState<string>(term ?? '');

  const endpoint = page === 'main' ? '/search' : '/admin';

  useEffect(() => {
    if (!school && !search) {
      return;
    }
    navigate(`${endpoint}?term=${search}&school=${school}`);
  }, [school]);

  const submitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    navigate(`${endpoint}?term=${search}&school=${school}`);
  };

  return (
    <form onSubmit={submitForm} style={{ display: 'flex', gap: 20 }}>
      <Grid container spacing={2}>
        {page === 'main' && (
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '2px 5px 15px rgba(0, 0, 0, 0.1)',
                px: 1,
                height: 58
              }}
            >
              <FormControl size="small" fullWidth>
                <InputLabel size="small"></InputLabel>
                <Select
                  native={false}
                  displayEmpty={true}
                  sx={{ '.MuiInputBase-input': { pb: 0 } }}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={school}
                  onChange={(e) => setSchool(e.target.value)}
                  disableUnderline
                  startAdornment={<School color="disabled" sx={{ pr: 2 }} />}
                >
                  <MenuItem value="">{universitiesQuery.isLoading ? 'Loading ...' : 'All Universities'}</MenuItem>
                  {universitiesQuery?.data?.map((university) => (
                    <MenuItem key={university.name} value={university.name}>
                      {university.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={8}>
          <Box
            sx={{
              flex: 1,
              py: 1,
              px: 1,
              backgroundColor: 'white',
              borderRadius: 2,
              display: 'flex',
              gap: 3,
              alignItems: 'center',
              boxShadow: '2px 5px 15px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Search color="disabled" />
            <InputBase
              sx={{ flex: 1, fontSize: 16 }}
              placeholder="Enter name here"
              inputProps={{ 'aria-label': 'search for students' }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <Button type="submit" variant="contained" size="large" color={'primary'}>
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
