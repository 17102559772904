import { useEffect } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { FirestoreError, QuerySnapshot } from 'firebase/firestore';
import { IPerson } from '../utils/data';

const OFFSET = 100; // 0 means the user must scroll to the very bottom pixel. 100 means the refetch will happen 100px away from the bottom.

// A custom hook that fetches the next page whenever the user scrolls to the bottom.

function useFetchNextPage(peopleQuery: UseInfiniteQueryResult<QuerySnapshot<IPerson>, FirestoreError>) {
  useEffect(() => {
    const listener = async () => {
      const current = document.documentElement.clientHeight + window.scrollY;
      const bottom = document.documentElement.scrollHeight;
      const isScrollAtBottom = current + OFFSET >= bottom;

      if (isScrollAtBottom && peopleQuery.hasNextPage && !peopleQuery.isFetchingNextPage) {
        await peopleQuery.fetchNextPage();
      }
    };

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [peopleQuery]);
}

export default useFetchNextPage;
