import { useNavigate, useParams } from 'react-router-dom';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';
import { IPerson, IPost, peopleCollection } from '../../utils/data';
import { doc } from 'firebase/firestore';
import { Box, Container, Grid, Link, Paper, Typography } from '@mui/material';
import Post from '../../components/Post';
import './Profile.css';
import PersonCard from '../../components/PersonCard';

export default function Profile() {
  let { id } = useParams();

  const personData = useFirestoreDocumentData<IPerson>(['people', id], doc(peopleCollection, id));
  const postsCount = personData?.data?.posts.filter((p) => !p.isDeleted).length;

  const navigate = useNavigate();

  if (!personData.data) {
    return null;
  }
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Link component="button" className="back_button__Profile" onClick={() => navigate(-1)}>
        &lt; Back
      </Link>
      <Typography variant="h5">Terror supporter profile</Typography>
      <PersonCard person={personData.data} />

      <Typography variant="h5">{postsCount} posts identified</Typography>

      <Box>
        <Grid container direction="row" spacing={2}>
          {personData.data.posts.map((post: IPost, index) => {
            if (post.isDeleted) {
              return null;
            }
            return (
              <Grid item key={post.link + index} xs={12} md={6} display="flex">
                <Grid
                  container
                  sx={{
                    padding: 2,
                    background: '#fff',
                    borderRadius: 2,
                    display: 'flex',
                    alignSelf: 'stretch',
                    gap: 3,
                    border: '1px solid #ddd',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'center'
                  }}
                >
                  {/* @ts-ignore  */}
                  <Post post={post} key={index} person={personData.data} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
}
