import { Container, Typography } from '@mui/material';

export default function About() {
  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 5 }}>
      <Typography variant="h5">About Don't Hire Hate</Typography>

      <Typography>
        <i>Don’t Hire Hate</i> is a media and data platform that aims to shine light on university students and faculty
        who publicly support acts of terror and condemn Israel for defending itself.
      </Typography>

      <Typography>
        Utilizing publicly shared information, the platform documents terror supporters on their website and generates
        media posts that surface when googling their name. Firms can use this platform to be sure their employees’
        values are in line with truth, morality, and justice.
      </Typography>

      <Typography>
        As most of us are aware, this war isn't confined to the battlefield, but is also in the media.
      </Typography>

      <Typography>
        Interested in joining the battle? Submit your case here{' '}
        <a href="https://donthirehate.com/report">https://donthirehate.com/report</a>
      </Typography>

      <Typography>This platform is designed to hold those who support acts of terror accountable.</Typography>

      <Typography>
        <i>
          Please only submit cases that clearly express such support. It is important that all cases submitted have the
          necessary information to qualify for a post (see form for details). Individuals are manually verifying these
          cases, so please be diligent in vetting any submissions. Thank you!
        </i>
      </Typography>
    </Container>
  );
}
