import { IPerson } from '../utils/data';
import { Box, IconButton, Link } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter, MusicNote } from '@mui/icons-material';
import * as React from 'react';

export default function SocialLinks({ person }: { person: IPerson }) {
  return (
    <Box sx={{ height: 40 }} onClick={(e) => e.stopPropagation()}>
      {person.facebook_url && (
        <IconButton component={Link} target="_blank" href={person.facebook_url}>
          <Facebook />
        </IconButton>
      )}
      {person.linkedin_url && (
        <IconButton component={Link} target="_blank" href={person.linkedin_url}>
          <LinkedIn />
        </IconButton>
      )}
      {person.twitter_url && (
        <IconButton component={Link} target="_blank" href={person.twitter_url}>
          <Twitter />
        </IconButton>
      )}
      {person.instagram_url && (
        <IconButton component={Link} target="_blank" href={person.instagram_url}>
          <Instagram />
        </IconButton>
      )}
      {person.tiktok_url && (
        <IconButton component={Link} target="_blank" href={person.tiktok_url}>
          <MusicNote />
        </IconButton>
      )}
    </Box>
  );
}
