import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: '#362B2B',
      light: '#EAEAFF'
    },
    secondary: {
      main: '#DD1E20',
      light: '#FEEAEA'
    },
    background: {
      default: '#e4f0e2'
    }
  },
  components: {
    MuiChip: {
      defaultProps: {
        style: {
          borderRadius: 2
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        style: {
          textTransform: 'initial'
        }
      }
    }
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <App />
          </ConfirmProvider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  </QueryClientProvider>
);
