import { DoNotDisturbOn } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseSVG from '../../assets/icons/close.svg';
import HamburgerSVG from '../../assets/icons/hamburger.svg';
import { auth } from '../../utils/firebase';
import { useAuthUser } from '@react-query-firebase/auth';
import { Box } from '@mui/material';
import './NavBar.css';

function NavBar() {
  const navigate = useNavigate();
  const userQuery = useAuthUser(['user'], auth);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (route: string) => {
    setIsMenuOpen(false);
    navigate(route);
  };

  const items = userQuery.data
    ? [
        { label: 'Home', route: '/' },
        { label: 'About', route: 'about' },
        { label: 'Report', route: 'report' }
      ]
    : [
        { label: 'Home', route: '/' },
        { label: 'About', route: 'about' },
        { label: 'Report', route: 'report' }
      ];

  return (
    <Box className="container__NavBar">
      <Box
        className="site_logo_container__NavBar"
        onClick={() => {
          handleClick('/');
        }}
      >
        <DoNotDisturbOn color="secondary" fontSize="large" />
        <span className="site_name__NavBar">Don't Hire Hate</span>
      </Box>
      <Box className="nav_links_container__NavBar">
        <Box className="mobile_header__NavBar">
          <Box
            className={`menu_img_container__NavBar${!isMenuOpen ? ' animateHide' : ''}`}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <img
              className={`close_icon__NavBar${isMenuOpen ? ' open_menu__NavBar' : ''}`}
              src={CloseSVG}
              alt="close menu logo"
            />
          </Box>
          <Box
            className={`menu_img_container__NavBar${isMenuOpen ? ' hide' : ''}`}
            onClick={() => {
              setIsMenuOpen(true);
            }}
          >
            <img className="hamburger_icon__NavBar" src={HamburgerSVG} alt="open menu logo" />
          </Box>
        </Box>

        <Box className={`menu_open_container__NavBar${isMenuOpen ? ' open_menu__NavBar' : ' hide'}`}>
          {items.map((item) => {
            return (
              <span
                key={item.label}
                className="nav_link_menu_item__NavBar"
                onClick={() => {
                  handleClick(item.route);
                }}
              >
                {item.label}
              </span>
            );
          })}
        </Box>
      </Box>
      <Box className="right_container__NavBar">
        <span
          className="nav_link__NavBar"
          onClick={() => {
            setIsMenuOpen(false);
            handleClick('about');
          }}
        >
          About Us
        </span>
        <Box
          className="report_button__NavBar"
          onClick={() => {
            handleClick('report');
          }}
        >
          <span className="report_button_text__NavBar">Report</span>
        </Box>
      </Box>
    </Box>
  );
}

export default NavBar;
