import { Dispatch, SetStateAction } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { IPost } from '../../utils/data';
import useFileUpload from '../../hooks/useFileUpload';
import Box from '@mui/material/Box';
import { fileTypes } from '../../utils/fileTypes';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import './DragDrop.css';
import { Typography } from '@mui/material';

function DragDrop({ posts, setPosts }: { posts: IPost[]; setPosts: Dispatch<SetStateAction<IPost[]>> }) {
  const { uploadFile } = useFileUpload();
  const handleChange = async (file: File) => {
    const fileLink = await uploadFile(file);
    if (!fileLink) {
      return;
    }

    setPosts([...posts, { type: 'screenshot', link: fileLink, isDeleted: false }]);
  };

  const handleDeletePost = (index: number) => {
    setPosts((prev) => {
      const clone = [...prev];
      clone.splice(index, 1);
      return clone;
    });
  };

  return (
    <>
      <FileUploader
        label="Upload or drag a photo of terror supporter"
        classes="file_uploader__DragDrop"
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        style={{ minWidth: 'unset' }}
      />
      {posts.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            overflowX: 'scroll',
            border: '2px dashed #ccc',
            borderRadius: 1,
            padding: 1
          }}
        >
          {posts.map((post, index) => (
            <Box key={post.link} display="flex" flexDirection="column" alignItems="center" gap={1}>
              <RemoveCircleIcon
                onClick={() => {
                  handleDeletePost(index);
                }}
                color="secondary"
                sx={{ cursor: 'pointer' }}
              />
              <img src={post.link} style={{ display: 'block', height: 100 }} alt="link" />
            </Box>
          ))}
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: '#FFEAA1',
          border: '2px solid rgba(111, 86, 0, 0.17)',
          paddingY: 2,
          paddingX: 3
        }}
      >
        <Typography color="#5A616C" fontSize={14}>
          Even if you linked to a post on social media, please upload a screenshot, in case the post is removed.
        </Typography>
      </Box>
    </>
  );
}

export default DragDrop;
