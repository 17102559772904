import { useAuthUser, useAuthSignOut } from '@react-query-firebase/auth';
import { auth } from '../utils/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import AdminDirectory from '../components/AdminDirectory';

export default function Admin() {
  const userQuery = useAuthUser(['user'], auth);
  const signOutMutation = useAuthSignOut(auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [onlyShowToReview, setOnlyShowToReview] = useState<boolean>(true);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError('false');
      userQuery.refetch();
    } catch (error) {
      const errorCode = (error as { code: string }).code;
      setError(errorCode);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs sx={{ flex: 1 }}>
          <Typography variant="h5">Admin</Typography>
        </Breadcrumbs>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={onlyShowToReview} onChange={(e) => setOnlyShowToReview(e.currentTarget.checked)} />
            }
            label="Only show non-reviewed"
          />
        </FormGroup>
        {userQuery.data && (
          <Button variant="contained" onClick={() => signOutMutation.mutate()}>
            Logout
          </Button>
        )}
      </Box>
      {userQuery.isLoading ? (
        <CircularProgress />
      ) : userQuery.data != null ? (
        <AdminDirectory onlyShowToReview={onlyShowToReview} />
      ) : (
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            margin: '20px auto',
            gap: '20px',
            maxWidth: '0.5',
            textAlign: 'center',
            borderRadius: '15px',
            minWidth: '240px'
          }}
        >
          <Typography variant="h5">Authentication</Typography>
          <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={'password'}
          />
          <Button onClick={handleLogin} variant="contained">
            Login
          </Button>

          {error !== '' && (
            <Typography color={'red'} sx={{ textAlign: 'center' }}>
              {error}
            </Typography>
          )}
        </Paper>
      )}
    </Container>
  );
}
