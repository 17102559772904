import { Grid, Typography } from '@mui/material';
import { IPerson, IPost } from '../utils/data';
import Box, { BoxProps } from '@mui/material/Box';
import LinkPost from './LinkPost';
import React from 'react';

export default function Post({ post, person, ...props }: { post: IPost; person?: IPerson } & BoxProps) {
  const ThisPostsIsDeletedText = () => {
    if (post.isDeleted) {
      return <Typography sx={{ color: 'red' }}>This post is DELETED.</Typography>;
    }

    return null;
  };
  if (!person || !post.link) {
    return null;
  }
  if (post.type === 'screenshot') {
    return (
      <Box {...props} sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <ThisPostsIsDeletedText />
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <img
            src={post.link}
            alt="screenshot"
            style={{ maxHeight: '650px', maxWidth: '70%', justifySelf: 'center', alignSelf: 'center' }}
          />
        </Grid>
      </Box>
    );
  } else if (post.type === 'link') {
    return (
      <Box {...props} sx={{ width: '100%', alignSelf: 'center', justifySelf: 'center' }}>
        {post.isDeleted && <Typography>This post is DELETED.</Typography>}
        <LinkPost post={post} person={person}></LinkPost>
      </Box>
    );
  }

  return null;
}
